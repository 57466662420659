import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import {
  gray,
  darkGray,
  smallerText,
  normalText
} from 'src/Styles/settings/Constants'
import BankAccountFragment from 'src/UIComponents/TransferDetail/Detail/BankAccountFragment'

const TransferSenderInfoCard = ({ sender, transferType, bankAccount }) => {
  const name = sender.first_name
    ? `${sender.first_name} ${sender.last_name}`
    : sender.business_name
  const documentLabel = sender.ein ? 'CNPJ' : 'CPF'
  const document = sender.ein
    ? sender.ein?.toDocument()
    : sender.taxpayer_id?.toDocument()
  const email = sender.email || sender.business_email

  return (
    <Grid noPadding>
      <Grid.Row auto>
        <SubTitleContainer>
          <Title noTransform small line>
            <strong>Conta</strong> de Origem
          </Title>
        </SubTitleContainer>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              Nome do Titular
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {name}
            </Label>
          </Grid.Row>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              {documentLabel}
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {document}
            </Label>
          </Grid.Row>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              E-mail
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {email}
            </Label>
          </Grid.Row>
          {transferType === 'cash_in' && (
            <BankAccountFragment bankAccount={bankAccount} />
          )}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
}
const SubTitleContainer = styled.div`
  margin-left: -10px;
`

TransferSenderInfoCard.propTypes = {
  sender: PropTypes.object,
  transferType: PropTypes.string,
  bankAccount: PropTypes.object
}

export default TransferSenderInfoCard
