import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Card from 'src/UIComponents/Card/Card'
import Label from 'src/UIComponents/Label/Label'
import { bigText, darkGray, margin, smallMargin, bigMargin } from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'

const VisualIdentityCloseDisclaimer = ({
  submit,
  dismiss
}) => (
  <Card withShadow padding='2rem'>
    <StyledLabel color={darkGray} fontSize={bigText}>
      Você não salvou suas alterações de cor e logo. Deseja realmente sair?
    </StyledLabel>
    <StyledButton onClick={submit} widthAuto>
      Continuar editando
    </StyledButton>
    <StyledButton inversed widthAuto onClick={dismiss}>
      Sair
    </StyledButton>
  </Card>
)

const StyledButton = styled(Button)`
  padding: 0.8rem 2.5rem;

  &:not(:last-of-type) {
    margin-bottom: ${smallMargin};
  }
`

const StyledLabel = styled(Label)`
  margin: 0 ${bigMargin} ${margin} ${bigMargin};
  text-align: center;
`

VisualIdentityCloseDisclaimer.propTypes = {
  submit: PropTypes.func,
  dismiss: PropTypes.func
}

export default VisualIdentityCloseDisclaimer
