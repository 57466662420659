import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import StatusBar from 'src/UIComponents/StatusBar/StatusBar'
import { TransferStatus } from 'src/Constants'
import { transferTypes } from 'src/Constants/TransferTypes'
import { toMoney } from 'src/Utils/Utils'
import { smallerText, normalText, darkGray, darkerGray } from 'src/Styles/settings/Constants'

const TransferResume = ({ transfer: { status, amount, type, id } }) => {
  return (
    <Container>
      <br />
      <br />
      <br />
      <Grid>
        <StatusBar color={TransferStatus[status].color} />
      </Grid>
      <br />
      <Grid.Row>
        <Grid.Col cols={7}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              Valor
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={TransferStatus[status].color} fontSize={normalText} bold>
              {toMoney(amount)}
            </Label>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col cols={9}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              Tipo
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={TransferStatus[status].color} fontSize={normalText} bold>
              {transferTypes[type].label}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row noMargin auto>
        <Grid.Col cols={15}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              ID da transação
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {id}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  )
}
const Container = styled.div``
TransferResume.propTypes = {
  transfer: PropTypes.object,
  sender: PropTypes.object
}

export default TransferResume
