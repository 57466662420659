import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

import { toMoney } from 'src/Utils/Utils'
import { getTransfersTypeLabels } from 'src/Constants/TransfersTypeLabels'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import Chevron from 'src/UIComponents/Chevron/Chevron'
import Grid from 'src/UIComponents/Grid/Grid'
import List from 'src/UIComponents/List/List'
import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Dots from 'src/UIComponents/Dots/Dots'
import TransferHistoryLoader from 'src/UIComponents/TransferDetail/History/TransferHistoryLoader'

import {
  darkGray,
  red,
  smallText,
  smallerText
} from 'src/Styles/settings/Constants'

const TransferHistory = ({
  transfer,
  transferHistory,
  transferQueryCount,
  transferHistoryHasMore,
  transferHistoryRequesting,
  fetchMoreData,
  requesting
}) => {
  const history = useHistory()

  if (requesting || !transfer) {
    return <TransferHistoryLoader />
  }

  const { type, id } = transfer

  const handleClick = (entry) => {
    if (entry.hasOwnProperty('transfer')) {
      history.push(`${id}/transferencia/${entry.transfer.id}`)
    } else if (entry.hasOwnProperty('transaction')) {
      history.push(`${id}/venda/${entry.transaction.id}`)
    }
  }

  if (!['payout_automatic', 'cash_out_automatic'].includes(type)) {
    return (
      <EmptyState
        iconSrc='/assets/icons/others/sales.svg'
        iconColor={darkGray}
        title='Não há vendas associadas a essa transferência'
        description={`As transferências ${getTransfersTypeLabels(type).toLowerCase()} nunca são\n associadas a nenhuma venda porque são feitas a\n partir do saldo disponível na conta de origem.`}
      />
    )
  }

  if (!transferHistoryRequesting && transferHistory.length === 0) {
    return (
      <EmptyState
        iconSrc='/assets/icons/others/sales.svg'
        iconColor={darkGray}
        title='Não há vendas associadas a essa transferência'
      />
    )
  } else {
    const showAmount = transfer.status !== 'scheduled'
    return (
      <GridSpaced noPadding>
        <Grid.Row>
          <Grid.Col offset={1} cols={14}>
            <Grid.Row auto>
              <Label fontSize={smallText} color={darkGray}>
                O valor total dessa transferência é composto por uma série de
                operações, aqui você pode conferir o detalhamento referente ao
                valor transferido.
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Grid.Col cols={16}>
                <Label textAlign='right' color={darkGray}>
                  {transferQueryCount} operações
                </Label>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row auto>
              <List
                line
                items={transferHistory.map(ListItem(handleClick, showAmount))}
              />
            </Grid.Row>
            <Grid.Row auto>
              {transferHistoryRequesting && transferHistory.length === 0 && (
                <Dots outline />
              )}
            </Grid.Row>
            <Grid.Row auto>
              {transferHistoryHasMore && transferHistory.length > 0 && (
                <StyledButton
                  inversed
                  widthAuto
                  isLoading={transferHistoryRequesting}
                  onClick={fetchMoreData}
                >
                  Carregar mais
                </StyledButton>
              )}
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </GridSpaced>
    )
  }
}

TransferHistory.propTypes = {
  transfer: PropTypes.object,
  transferHistory: PropTypes.arrayOf(PropTypes.object),
  transferQueryCount: PropTypes.number,
  transferHistoryRequesting: PropTypes.bool,
  fetchMoreData: PropTypes.func,
  requesting: PropTypes.bool
}

export default TransferHistory

const GridSpaced = styled(Grid)`
  margin-bottom: 4rem;
`

const StyledGridRow = styled(Grid.Row)`
  align-items: center;
  cursor: ${({ hasClick }) => hasClick && 'pointer'};
`

const StyledButton = styled(Button)`
  justify-self: center;
  padding-left: 3rem;
  padding-right: 3rem;
`

const LabelWrapper = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
`

const ListItem = (onClick, showAmount) => (history) => {
  const isSameTransfer =
    history.hasOwnProperty('transfer') &&
    history.transfer.id === history.object_id

  return (
    <StyledGridRow
      hasClick={!isSameTransfer}
      key={history.id}
      onClick={() => {
        !isSameTransfer && onClick(history)
      }}
    >
      <Grid.Col cols={showAmount ? 11 : 15}>
        <LabelWrapper>
          <Label color={darkGray} fontSize={smallerText}>
            {moment(history.date).format('DD/MM/YYYY')}{' '}
            {moment(history.date).format('HH:mm:ss')}
          </Label>
        </LabelWrapper>
        <LabelWrapper>
          <Label color={darkGray} fontSize={smallText}>
            {history.description}
          </Label>
        </LabelWrapper>
      </Grid.Col>
      {showAmount && (
        <Grid.Col cols={4}>
          <Label
            textAlign='right'
            color={history.amount > 0 ? darkGray : red}
            fontSize={smallText}
          >
            {toMoney(history.amount)}
          </Label>
        </Grid.Col>
      )}
      <Grid.Col cols={1}>{!isSameTransfer && <Chevron />}</Grid.Col>
    </StyledGridRow>
  )
}
