import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import { shortenText, toMoney } from 'src/Utils/Utils'
import StatusBar from 'src/UIComponents/StatusBar/StatusBar'
import { TransferStatus } from 'src/Constants'
import { transferTypes } from 'src/Constants/TransferTypes'
import ReactSVG from 'react-svg'
import Card from 'src/UIComponents/Card/Card'
import Title from 'src/UIComponents/Title/Title'
import {
  bigText,
  gray,
  darkGray,
  textBold,
  smallPadding,
  normalPadding,
  smallerText,
  normalText
} from 'src/Styles/settings/Constants'
import moment from 'moment'

const TransferResume = ({
  transfer: {
    status,
    amount,
    type,
    description,
    id,
    uri,
    transfer_number: transferNumber,
    transfer_date: transferDate
  }
}) => (
  <Container>
    <Grid>
      <StatusBar color={TransferStatus[status].color} />
      <Grid.Row auto noMargin>
        <Label
          color={TransferStatus[status].color}
          bold
          uppercase>
          {TransferStatus[status].label}
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Grid.Col>
          <Grid.Row auto noMargin>
            <Label color={gray}>
                Valor da Transferência
            </Label>
          </Grid.Row>
          <Grid.Row auto noMargin>
            <Label
              color={TransferStatus[status].color}
              bold
              uppercase
              fontSize={bigText}>
              {toMoney(amount)}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      {
        !['pending', 'scheduled'].includes(status) && (
          <>
            <Grid.Row auto noMargin>
              <Label color={gray} fontSize={smallerText}>
                Data do pagamento
              </Label>
            </Grid.Row>
            <Grid.Row>
              <Grid.Col cols={15}>
                <Label color={darkGray} fontSize={normalText}>
                  {moment(transferDate).utc(false).format('DD/MM/YYYY')}
                </Label>
              </Grid.Col>
            </Grid.Row>
          </>
        )
      }
      <Grid.Row auto noMargin>
        <Label color={gray} fontSize={smallerText}>
            Tipo
        </Label>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={15}>
          <Label color={darkGray} fontSize={normalText}>
            {transferTypes[type].label}
          </Label>
        </Grid.Col>
        <Grid.Col cols={1}>
          <TransferTypeIcon src={transferTypes[type].iconSrc} />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row auto noMargin>
        <Label color={gray} fontSize={smallerText}>
            Descrição
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <Label color={darkGray} fontSize={normalText}>
          {description}
        </Label>
      </Grid.Row>
      <Grid.Row auto noMargin>
        <Label color={gray} fontSize={smallerText}>
            ID da Transferência
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <LabelCopyToClipboard text={id} size='normal'>
          {id}
        </LabelCopyToClipboard>
      </Grid.Row>
      {
        transferNumber && (
          <Fragment>
            <Grid.Row auto noMargin>
              <Label color={gray} fontSize={smallerText}>
                  Nº do Comprovante
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <LabelCopyToClipboard text={transferNumber} size='normal'>
                {transferNumber}
              </LabelCopyToClipboard>
            </Grid.Row>
          </Fragment>
        )
      }
      <Grid.Row auto noMargin>
        <Label color={gray} fontSize={smallerText}>
            URI
        </Label>
      </Grid.Row>
      <Grid.Row auto>
        <LabelCopyToClipboard text={uri} size='normal'>
          {shortenText(uri)}
        </LabelCopyToClipboard>
      </Grid.Row>
      {
        ['pending', 'scheduled'].includes(status) && (
          <>
            <Grid.Row auto noMargin>
              <StyledCard withShadow>
                <Label fontSize={smallerText} uppercase color={darkGray}>
                  <span>
                    Transferência <Bold>{status === 'scheduled' ? 'agendada' : 'prevista'} para</Bold>
                  </span>
                </Label>
                <Title>
                  <strong>
                    {moment(transferDate).utc().isSame(new Date(), 'day')
                      ? 'Hoje*'
                      : `${moment(transferDate).utc().format('DD/MM/YYYY')} ${status === 'pending' ? '*' : ''}`}
                  </strong>
                </Title>
              </StyledCard>
            </Grid.Row>
          </>
        )
      }
    </Grid>
  </Container>
)

const Container = styled.div`
`

const TransferTypeIcon = styled(ReactSVG)`
  svg {
    width: 24px;
    height: 23px;
    color: ${darkGray};
  }
`
const Bold = styled.span`
  font-weight: ${textBold};
`

const StyledCard = styled(Card)`
  padding: ${smallPadding} ${normalPadding};
`

TransferResume.propTypes = {
  transfer: PropTypes.object
}

export default TransferResume
