import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import styled from 'styled-components'
import { gray } from 'src/Styles/settings/Constants'
import TransferResume from 'src/UIComponents/TransferDetail/Detail/TransferResume'
import TransferSenderInfoCard from 'src/UIComponents/TransferDetail/Detail/TransferSenderInfoCard'
import TransferRecipientInfoCard from 'src/UIComponents/TransferDetail/Detail/TransferRecipientInfoCard'
import DetailLoader from 'src/UIComponents/TransferDetail/Detail/DetailLoader'

const Detail = ({ transfer, sender, recipient, requesting }) => (
  <Container>
    <Grid noPadding>
      {(requesting || !transfer) && (
        <DetailLoader />
      )}
      {!requesting && transfer && (
        <Fragment>
          <Grid.Row auto bigMarginBottom>
            <TransferResume transfer={transfer} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={14} offset={1}>
              {sender && <Grid.Row auto bigMarginBottom>
                <TransferSenderInfoCard
                  sender={sender}
                  transferType={transfer.type}
                  bankAccount={transfer.bank_account} />
              </Grid.Row>}
              {recipient && <Grid.Row auto bigMarginBottom>
                <TransferRecipientInfoCard
                  recipient={recipient}
                  transferType={transfer.type}
                  bankAccount={transfer.bank_account} />
              </Grid.Row>}
            </Grid.Col>
          </Grid.Row>
        </Fragment>
      )}
    </Grid>
  </Container>
)

const Container = styled.div`
  color: ${gray};
  overflow: auto;
  height: 100%;
`

Detail.propTypes = {
  transfer: PropTypes.object,
  sender: PropTypes.object,
  recipient: PropTypes.object,
  requesting: PropTypes.bool
}

export default Detail
