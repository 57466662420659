import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
// import { useSelector } from 'react-redux'

import Title from 'src/UIComponents/Title/Title'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
// import { getReceipt } from 'src/Actions/receipt'
import {
  smallerText,
  normalText,
  darkGray,
  darkerGray
} from 'src/Styles/settings/Constants'

function TransferSenderInfo({ sender }) {
  const isIndividual = sender && sender.taxpayer_id

  return (
    <Container>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> de Origem
        </Title>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              Nome
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {sender.business_name ||
                `${sender.first_name} ${sender.last_name}`}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              {isIndividual ? 'CPF' : 'CNPJ'}
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {(sender.taxpayer_id || sender.ein)?.toDocument()}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              Email
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {sender.business_email || sender.email}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Container>
  )
}
const Container = styled.div``

TransferSenderInfo.propTypes = {
  sender: PropTypes.object
}

export default TransferSenderInfo
