import React from 'react'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import Title from 'src/UIComponents/Title/Title'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'

import {
  smallerText,
  normalText,
  darkGray,
  darkerGray
} from 'src/Styles/settings/Constants'

function TransferReceiverInfo({ receiver, transfer }) {
  const isIndividual = receiver?.taxpayer_id
  const isBankAccountTransfer = receiver?.bank_code && receiver?.account_number

  return (
    <Container>
      <Grid.Row auto>
        <Title noTransform noPadding small line>
          <strong>Dados</strong> de Destino
        </Title>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              Nome
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {transfer.bank_account?.business_name ||
                transfer?.bank_account?.holder_name ||
                `${receiver?.first_name} ${receiver?.last_name}`}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row noMargin auto>
            <Label fontSize={smallerText} color={darkGray}>
              {isIndividual ? 'CPF' : 'CNPJ'}
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label overflow color={darkerGray} fontSize={normalText}>
              {(
                transfer.bank_account?.taxpayer_id || transfer.bank_account?.ein
              )?.toDocument()}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      {!isBankAccountTransfer && (
        <Grid.Row smallMarginBottom>
          <Grid.Col cols={16}>
            <Grid.Row noMargin auto>
              <Label fontSize={smallerText} color={darkGray}>
                Email
              </Label>
            </Grid.Row>
            <Grid.Row auto>
              <Label overflow color={darkerGray} fontSize={normalText}>
                {receiver?.business_email || receiver?.email}
              </Label>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      )}
      {isBankAccountTransfer && (
        <>
          <Grid.Row smallMarginBottom>
            <Grid.Col cols={16}>
              <Grid.Row noMargin auto>
                <Label fontSize={smallerText} color={darkGray}>
                  Instituição
                </Label>
              </Grid.Row>
              <Grid.Row auto>
                <Label overflow color={darkerGray} fontSize={normalText}>
                  {transfer.bank_account?.bank_name}
                </Label>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row smallMarginBottom>
            <Grid.Col cols={8}>
              <Grid.Row noMargin auto>
                <Label fontSize={smallerText} color={darkGray}>
                  Agência
                </Label>
              </Grid.Row>
              <Grid.Row auto>
                <Label overflow color={darkerGray} fontSize={normalText}>
                  {transfer.bank_account?.routing_number}
                </Label>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col cols={8}>
              <Grid.Row noMargin auto>
                <Label fontSize={smallerText} color={darkGray}>
                  Conta
                </Label>
              </Grid.Row>
              <Grid.Row auto>
                <Label overflow color={darkerGray} fontSize={normalText}>
                  {transfer.bank_account?.account_number}
                </Label>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </Container>
  )
}

const Container = styled.div``

TransferReceiverInfo.propTypes = {
  receiver: PropTypes.object,
  transfer: PropTypes.object
}

export default TransferReceiverInfo
