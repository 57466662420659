import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { lightGray } from '../../Styles/settings/Constants'

const VerticalLine = ({ successColor }) => (
  <Line successColor={successColor} />
)

VerticalLine.propTypes = {
  successColor: PropTypes.bool
}

const Line = styled.div`
  position:absolute;
  top: 1.6rem;
  height: 100%;
  min-height: 1.5rem;
  width: 1px;
  background-color: ${props => props.successColor ? props.theme.color : lightGray};
`

export default VerticalLine
