import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import { normalText, smallerText, darkGray, gray } from 'src/Styles/settings/Constants'
import BankAccountIcon from 'src/Components/BankAccountIcon/BankAccountIcon'

const BankAccountFragment = ({
  bankAccount
}) => {
  const {
    account_number: accountNumber,
    routing_number: routingNumber,
    routing_check_digit: routingCheckDigit,
    bank_code: bankCode
  } = bankAccount

  return (
    <Fragment>
      <Grid.Row auto noMargin>
        <Label color={gray} fontSize={smallerText}>
          Banco
        </Label>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={16}>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              <BankAccountIcon bankCode={bankCode} />
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col cols={4}>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              Agência {routingCheckDigit && '- Dígito'}
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {routingNumber} {routingCheckDigit && '-' + routingCheckDigit}
            </Label>
          </Grid.Row>
        </Grid.Col>
        <Grid.Col cols={9}>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              Conta Corrente
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {accountNumber}
            </Label>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Fragment>
  )
}

BankAccountFragment.propTypes = {
  bankAccount: PropTypes.object
}

export default BankAccountFragment
