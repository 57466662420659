import React from 'react'
import ContentLoader from 'react-content-loader'

const TransferHistoryLoader = (props) => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={215}
    viewBox='0 0 385 215'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='20' y='15' rx='0' ry='0' width='340' height='37' />
    <rect x='20' y='48' rx='0' ry='0' width='111' height='21' />
    <rect x='288' y='88' rx='0' ry='0' width='78' height='15' />
    <rect x='20' y='185' rx='0' ry='0' width='223' height='22' />
    <rect x='280' y='185' rx='0' ry='0' width='56' height='22' />
    <rect x='345' y='185' rx='0' ry='0' width='19' height='22' />
    <rect x='20' y='135' rx='0' ry='0' width='223' height='22' />
    <rect x='280' y='136' rx='0' ry='0' width='56' height='22' />
    <rect x='345' y='137' rx='0' ry='0' width='19' height='22' />
  </ContentLoader>
)

export default TransferHistoryLoader
