import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { lightGray, bigMargin, middleMargin, lightestGray } from 'src/Styles/settings/Constants'

const TopHeaderMarketplace = ({
  logo,
  menu,
  addPos,
  headerUser,
  anticipation,
  selectSellerContext
}) => (
  <>

    {headerUser}
    <MenuContainer>
      <SplitContainer>
        <LogoContainer>
          {logo}
        </LogoContainer>
        <NavContainer>
          {menu}
        </NavContainer>
      </SplitContainer>
      <SplitContainer>
        <AnticipationContainer>
          {anticipation}
        </AnticipationContainer>
        <AddPosContainer>
          {addPos}
        </AddPosContainer>
        <SelectSellerContextContainer>
          {selectSellerContext}
        </SelectSellerContextContainer>
      </SplitContainer>
    </MenuContainer>
  </>
)

TopHeaderMarketplace.propTypes = {
  headerUser: PropTypes.object,
  logo: PropTypes.object,
  menu: PropTypes.object,
  addPos: PropTypes.object,
  anticipation: PropTypes.object,
  selectSellerContext: PropTypes.object
}

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 30px;
  height: 86px;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 2px solid ${lightGray};
  background-color: ${lightestGray};
  z-index: 99;
  position: sticky;
  top: 0;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: auto;
`

const NavContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  position: relative;
  top: 15px;
  margin-left: ${bigMargin};
`

const SplitContainer = styled.div`
  display: flex;
`

const AddPosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  margin-right: ${middleMargin};
`

const AnticipationContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 200px !important;
  margin-right: 0.5rem;
`

const SelectSellerContextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`

export default TopHeaderMarketplace
