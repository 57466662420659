import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import Title from 'src/UIComponents/Title/Title'
import LabelCopyToClipboard from 'src/UIComponents/LabelCopyToClipboard/LabelCopyToClipboard'
import {
  normalText,
  smallerText,
  gray,
  darkGray
} from 'src/Styles/settings/Constants'
import BankAccountFragment from 'src/UIComponents/TransferDetail/Detail/BankAccountFragment'

const TransferRecipientInfoCard = ({
  recipient,
  transferType,
  bankAccount
}) => {
  const name = recipient.first_name
    ? `${recipient.first_name} ${recipient.last_name || ''}`
    : recipient.business_name
  const documentLabel = recipient.ein ? 'CNPJ' : 'CPF'
  const document = recipient.ein
    ? recipient?.ein?.toDocument()
    : recipient?.taxpayer_id?.toDocument()
  const email = recipient.email || recipient.business_email
  const id = recipient.id

  return (
    <Grid noPadding>
      <Grid.Row auto>
        <SubTitleContainer>
          <Title noTransform small line>
            <strong>Dados</strong> do Favorecido
          </Title>
        </SubTitleContainer>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              Nome do Titular
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {name}
            </Label>
          </Grid.Row>
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              {documentLabel}
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <Label color={darkGray} fontSize={normalText}>
              {document}
            </Label>
          </Grid.Row>
          {!['payout_manual', 'payout_automatic'].includes(transferType) && (
            <Fragment>
              <Grid.Row auto noMargin>
                <Label color={gray} fontSize={smallerText}>
                  E-mail
                </Label>
              </Grid.Row>
              <Grid.Row auto>
                <Label color={darkGray} fontSize={normalText}>
                  {email}
                </Label>
              </Grid.Row>
            </Fragment>
          )}
          <Grid.Row auto noMargin>
            <Label color={gray} fontSize={smallerText}>
              ID do Favorecido
            </Label>
          </Grid.Row>
          <Grid.Row auto>
            <LabelCopyToClipboard text={id} size='normal'>
              {id}
            </LabelCopyToClipboard>
          </Grid.Row>
          {['payout_manual', 'payout_automatic'].includes(transferType) &&
            bankAccount && <BankAccountFragment bankAccount={bankAccount} />}
        </Grid.Col>
      </Grid.Row>
    </Grid>
  )
}
const SubTitleContainer = styled.div`
  margin-left: -10px;
`

TransferRecipientInfoCard.propTypes = {
  recipient: PropTypes.object,
  transferType: PropTypes.string,
  bankAccount: PropTypes.object
}

export default TransferRecipientInfoCard
