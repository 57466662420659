import React from 'react'
import ContentLoader from 'react-content-loader'

const DetailLoader = (props) => (
  <ContentLoader
    speed={4}
    width={'100%'}
    height={850}
    viewBox='0 0 385 850'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='30' y='8' rx='0' ry='0' width='69' height='15' />
    <rect x='30' y='28' rx='0' ry='0' width='124' height='13' />
    <rect x='30' y='46' rx='0' ry='0' width='85' height='18' />
    <rect x='30' y='83' rx='0' ry='0' width='34' height='13' />
    <rect x='30' y='100' rx='0' ry='0' width='147' height='17' />
    <rect x='329' y='95' rx='0' ry='0' width='30' height='29' />
    <rect x='30' y='138' rx='0' ry='0' width='50' height='12' />
    <rect x='30' y='156' rx='0' ry='0' width='87' height='14' />
    <rect x='30' y='187' rx='0' ry='0' width='90' height='13' />
    <rect x='30' y='207' rx='0' ry='0' width='243' height='14' />
    <rect x='30' y='241' rx='0' ry='0' width='22' height='11' />
    <rect x='30' y='257' rx='0' ry='0' width='256' height='15' />
    <rect x='30' y='306' rx='0' ry='0' width='108' height='20' />
    <rect x='60' y='346' rx='0' ry='0' width='75' height='10' />
    <rect x='60' y='364' rx='0' ry='0' width='103' height='15' />
    <rect x='60' y='397' rx='0' ry='0' width='25' height='11' />
    <rect x='60' y='414' rx='0' ry='0' width='106' height='18' />
    <rect x='60' y='448' rx='0' ry='0' width='33' height='13' />
    <rect x='60' y='465' rx='0' ry='0' width='181' height='19' />
    <rect x='60' y='499' rx='0' ry='0' width='34' height='12' />
    <rect x='60' y='518' rx='0' ry='0' width='125' height='37' />
    <rect x='220' y='518' rx='0' ry='0' width='67' height='25' />
    <rect x='60' y='571' rx='0' ry='0' width='42' height='14' />
    <rect x='60' y='590' rx='0' ry='0' width='36' height='12' />
    <rect x='116' y='572' rx='0' ry='0' width='71' height='11' />
    <rect x='116' y='587' rx='0' ry='0' width='91' height='16' />
    <rect x='30' y='640' rx='0' ry='0' width='135' height='20' />
    <rect x='60' y='680' rx='0' ry='0' width='77' height='11' />
    <rect x='60' y='696' rx='0' ry='0' width='103' height='17' />
    <rect x='60' y='730' rx='0' ry='0' width='29' height='12' />
    <rect x='60' y='746' rx='0' ry='0' width='99' height='16' />
    <rect x='60' y='781' rx='0' ry='0' width='39' height='13' />
    <rect x='60' y='798' rx='0' ry='0' width='184' height='17' />
  </ContentLoader>
)

export default DetailLoader
