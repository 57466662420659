import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { HeaderSellerUI } from 'src/UIComponents/HeaderSeller'
import BalanceSeller from 'src/Components/BalanceSeller/BalanceSeller'
import SellerHeaderInformation from 'src/Components/SellerHeaderInformation/SellerHeaderInformation'
import NavMenuSellerComponent from 'src/Components/NavMenuSellerComponent/NavMenuSellerComponent'
import AddPosComponent from 'src/Components/AddPosComponent/AddPosComponent'
import CreateTransferComponent from '../../Components/CreateTransferComponent/CreateTransferComponent'
import Can from '../../Helpers/Permission/Can'
import CreateSaleComponent from '../../Components/CreateSale/CreateSaleComponent'

const TopHeaderSellerUI = ({ marketplace }) => {
  return (
    <>
      <HeaderSellerUI marketplace={marketplace} />
      <Container>
        <SellerHeaderInformation />
        <BalanceSeller />
      </Container>

      <NavMenuContainer>
        <NavMenuSellerComponent />
        <ButtonsContainer>
          <Can I='pair' a='POS'>
            <AddPosComponent buttonInversed />
          </Can>
          <Can I='create' a='Transaction'>
            <CreateSaleComponent buttonInversed />
          </Can>
          <Can I='create' a='Transfer'>
            <CreateTransferComponent buttonInversed />
          </Can>
        </ButtonsContainer>
      </NavMenuContainer>

    </>
  )
}

TopHeaderSellerUI.propTypes = {
  marketplace: PropTypes.object
}

const Container = styled.div`
  padding: 20px 0px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #EBEBEB;
  margin: 0 30px;
`

const NavMenuContainer = styled.div`
  margin:0px 30px;
  padding: 10px 0px;
  border-bottom: 1px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
`

const ButtonsContainer = styled.div`
  display: flex;

  > Button {
    margin-left: 15px
  }
`

export default TopHeaderSellerUI
